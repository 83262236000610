.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60%;
}

.profilePic {
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  max-width: 100%;
  max-height: 300px;
}

.profilePic__dark {
  composes: profilePic;
  border-color: var(--fg_dark);
}

.profilePic__light {
  composes: profilePic;
  border-color: var(--fg_light);
}

.aboutMe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: 1.5rem;
  line-height: 40px;
  padding: 3%;
}

.aboutMe > p {
  margin: 10px 0px;
}

@media (min-width: 950px) {
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutMe {
    max-width: 50%;
  }
}

.project {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-bottom: 40px;
}

.projectName {
  text-decoration: none;
  color: inherit;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin: 0px;
}

.projectName:hover {
  text-decoration: underline;
}

.projectDescription {
  text-align: left;
  margin: 0px;
}

.App {
  text-align: center;
  background-color: lightblue;
  height: 100vh;
  overflow: scroll;
}

.dark {
  background-color: var(--bg_dark);
  color: var(--fg_dark);
}

.light {
  background-color: var(--bg_light);
  color: var(--fg_light);
}

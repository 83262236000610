.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.navbar {
  display: flex;
  justify-content: space-around;
}

.navbarLink {
  font-size: 2rem;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  margin: 0px 25px;
}

.navbarLink:hover {
  text-decoration: underline;
}

.navbarLink__dark {
  composes: navbarLink;
  color: var(--fg_dark);
}

.navbarLink__light {
  composes: navbarLink;
  color: var(--fg_light);
}

.toggleDark {
  display: flex;
  align-items: center;
  background-color: lightgrey;
  border-radius: 5px;
  width: 50px;
  height: 25px;
  margin-right: 2%;
  cursor: pointer;
}

.toggleLight {
  composes: toggleDark;
  justify-content: flex-end;
}

.darkIcon {
  background-color: var(--bg_dark);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 3px;
}

.lightIcon {
  composes: darkIcon;
  background-color: var(--bg_light);
}
